import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>VibeCheck: Discover and Quantify Qualitative Differences in LLMs</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      
      {/* Navigation Header */}
      <header className="bg-white shadow-md py-4">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            <h1 className="text-2xl font-bold">VibeCheck</h1>
            <nav className="mt-4 md:mt-0">
              <ul className="flex flex-wrap gap-6">
                <li>
                  <Link to="/visualizer" className="text-blue-600 hover:underline">Vibe Visualizer</Link>
                </li>
                <li>
                  <a href="https://arxiv.org/abs/2410.12851" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Paper</a>
                </li>
                <li>
                  <a href="https://github.com/lisadunlap/VibeCheck" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">GitHub</a>
                </li>
                <li>
                  <a href="https://huggingface.co/datasets/lmarena-ai/Llama-3-70b-battles" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Dataset</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      
      {/* Blog Post Content */}
      <main className="container mx-auto px-4 py-8 max-w-4xl">
        {/* Title and Authors */}
        <div className="mb-8 text-center">
          <h1 className="text-3xl md:text-4xl font-bold mb-6">
            VibeCheck: Discover and Quantify Qualitative Differences in Large Language Models
          </h1>
          <div className="flex flex-wrap justify-center gap-3 text-lg text-blue-600">
            <a href="https://www.lisabdunlap.com/" target="_blank" rel="noopener noreferrer" className="hover:underline">Lisa Dunlap</a>
            <span>•</span>
            <a href="https://www.linkedin.com/in/krishna-mandal/" target="_blank" rel="noopener noreferrer" className="hover:underline">Krishna Mandal</a>
            <span>•</span>
            <a href="https://darrellgroup.github.io/" target="_blank" rel="noopener noreferrer" className="hover:underline">Trevor Darrell</a>
            <span>•</span>
            <a href="https://jsteinhardt.stat.berkeley.edu/" target="_blank" rel="noopener noreferrer" className="hover:underline">Jacob Steinhardt</a>
            <span>•</span>
            <a href="https://joeygonzalez.com/" target="_blank" rel="noopener noreferrer" className="hover:underline">Joseph E Gonzalez</a>
          </div>
        </div>
        
        {/* Key Findings */}
        <section className="mb-6 bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4">Key Findings</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="border border-gray-200 rounded-lg p-4">
              <h3 className="font-semibold mb-2">Vibe Discovery</h3>
              <p>VibeCheck automatically discovers meaningful qualitative differences between LLMs that align with human intuition.</p>
            </div>
            <div className="border border-gray-200 rounded-lg p-4">
              <h3 className="font-semibold mb-2">Cross-Task Analysis</h3>
              <p>Different models show distinct vibes across summarization, math problem-solving, and image captioning tasks.</p>
            </div>
            <div className="border border-gray-200 rounded-lg p-4">
              <h3 className="font-semibold mb-2">Interactive Exploration</h3>
              <p>Explore model vibes and see example outputs with our <Link to="/visualizer" className="text-blue-600 hover:underline">interactive visualizer</Link>.</p>
            </div>
          </div>
        </section>
        
        {/* Abstract */}
        <section className="mb-6 bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4">Abstract</h2>
          <div className="prose max-w-none">
            <p>
              Large language models (LLMs) often exhibit subtle yet distinctive characteristics in their outputs that users intuitively recognize, but struggle to quantify. These "vibes" -- such as tone, formatting, or writing style -- influence user preferences, yet traditional evaluations focus primarily on the singular axis of correctness. We introduce VibeCheck, a system for automatically comparing a pair of LLMs by discovering identifying traits of a model (vibes) that are well-defined, differentiating, and user-aligned.
            </p>
            <p>
              VibeCheck iteratively discovers vibes from model outputs and then utilizes a panel of LLM judges to quantitatively measure the utility of each vibe. We validate that the vibes generated by VibeCheck align with those found in human discovery and run VibeCheck on pairwise preference data from real-world user conversations with Llama-3-70b vs GPT-4. VibeCheck reveals that Llama has a friendly, funny, and somewhat controversial vibe. These vibes predict model identity with 80% accuracy and human preference with 61% accuracy.
            </p>
            <p>
              Lastly, we run VibeCheck on a variety of models and tasks including summarization, math, and captioning to provide insight into differences in model behavior. VibeCheck discovers vibes like Command X prefers to add concrete intros and conclusions when summarizing in comparison to TNGL, Llama-405b often overexplains its thought process on math problems compared to GPT-4o, and GPT-4 prefers to focus on the mood and emotions of the scene when captioning compared to Gemini-1.5-Flash.
            </p>
          </div>
        </section>
        
        {/* Method */}
        <section className="mb-6 bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4">Method</h2>
          <div className="flex items-center justify-center mb-4">
            <img
              src={`${process.env.PUBLIC_URL}/method.jpeg`}
              alt="VibeCheck Method Diagram"
              className="w-full h-auto"
            />
          </div>
          <div className="prose max-w-none">
            <p>
              The VibeCheck system operates in three main phases: (1) vibe discovery, where we automatically identify candidate vibe descriptions that differentiate LLM outputs; (2) vibe validation, where we filter and refine vibes to ensure they are well-defined, distinctive, and user-aligned; and (3) vibe analysis, where we use the discovered vibes to analyze model outputs and predict user preferences.
            </p>
          </div>
        </section>
        
        {/* Citation */}
        <section className="mb-6 bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4">Citation</h2>
          <h3 className="text-lg font-medium mb-2">BibTeX</h3>
          <div className="bg-gray-100 p-3 rounded-md font-mono text-sm overflow-x-auto">
            <pre>
{`@article{dunlap2023vibecheck,
  title={VibeCheck: Discover and Quantify Qualitative Differences in Large Language Models},
  author={Dunlap, Lisa and Mandal, Krishna and Darrell, Trevor and Steinhardt, Jacob and Gonzalez, Joseph E},
  journal={International Conference on Learning Representations},
  year={2023},
  url={https://arxiv.org/abs/2309.12112}
}`}
            </pre>
          </div>
        </section>
      </main>
      
      {/* Footer */}
      <footer className="bg-white border-t py-6">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; {new Date().getFullYear()} VibeCheck Team. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage; 