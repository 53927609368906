import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';

const VibeTable = ({ 
  vibes, 
  sortColumn = 'score', 
  sortDirection = 'desc', 
  handleSort, 
  expandedVibes, 
  toggleVibeExpansion,
  hideVibesWithLowSeparability,
  toggleHideVibesWithLowSeparability
}) => {
  const displayedVibes = hideVibesWithLowSeparability 
    ? vibes.filter(vibe => Math.abs(vibe.score) >= 0.05)
    : vibes;

  return (
    <table className="min-w-full border-collapse">
      <thead>
        <tr>
          <th className="py-2 px-4 border-b-2 text-left cursor-pointer w-1/2" onClick={() => handleSort('vibe')}>
            Vibe {sortColumn === 'vibe' && (sortDirection === 'desc' ? '▼' : '▲')}
          </th>
          <th className="py-2 px-4 border-b-2 text-right cursor-pointer" onClick={() => handleSort('score')}>
            Model ID Score {sortColumn === 'score' && (sortDirection === 'desc' ? '▼' : '▲')}
          </th>
          <th className="py-2 px-4 border-b-2 text-right cursor-pointer" onClick={() => handleSort('pref_score')}>
            Preference Score {sortColumn === 'pref_score' && (sortDirection === 'desc' ? '▼' : '▲')}
          </th>
          <th className="py-2 px-4 border-b-2 text-right cursor-pointer" onClick={() => handleSort('coef_modelID')}>
            Model ID Coefficient {sortColumn === 'coef_modelID' && (sortDirection === 'desc' ? '▼' : '▲')}
          </th>
          <th className="py-2 px-4 border-b-2 text-right cursor-pointer" onClick={() => handleSort('coef_preference')}>
            Preference Coefficient {sortColumn === 'coef_preference' && (sortDirection === 'desc' ? '▼' : '▲')}
          </th>
        </tr>
      </thead>
      <tbody>
        {displayedVibes.map((vibe, index) => (
          <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
            <td className="py-2 px-4 border-b w-1/2" title={vibe.vibe}>
              <div 
                className={`cursor-pointer transition-all duration-200`}
                onClick={() => toggleVibeExpansion(index)}
              >
                <div className={`markdown-content prose prose-sm max-w-none ${expandedVibes[index] ? '' : 'line-clamp-3'}`}>
                  <ReactMarkdown 
                    remarkPlugins={[remarkMath]} 
                    rehypePlugins={[rehypeKatex]}
                  >
                    {vibe.vibe}
                  </ReactMarkdown>
                </div>
              </div>
            </td>
            <td className="py-2 px-4 border-b text-right">
              <span className={
                vibe.score < 0 ? 'text-red-600' : 
                vibe.score > 0 ? 'text-green-600' : 
                'text-gray-600'
              }>
                {vibe.score.toFixed(2)}
              </span>
            </td>
            <td className="py-2 px-4 border-b text-right">
              <span className={
                vibe.pref_score < 0 ? 'text-red-600' : 
                vibe.pref_score > 0 ? 'text-green-600' : 
                'text-gray-600'
              }>
                {vibe.pref_score.toFixed(2)}
              </span>
            </td>
            <td className="py-2 px-4 border-b text-right">
              <span className={
                vibe.coef_modelID < 0 ? 'text-red-600' : 
                vibe.coef_modelID > 0 ? 'text-green-600' : 
                'text-gray-600'
              }>
                {vibe.coef_modelID.toFixed(2)}
              </span>
            </td>
            <td className="py-2 px-4 border-b text-right">
              <span className={
                vibe.coef_preference < 0 ? 'text-red-600' : 
                vibe.coef_preference > 0 ? 'text-green-600' : 
                'text-gray-600'
              }>
                {vibe.coef_preference.toFixed(2)}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default VibeTable; 